<template>
    <div>
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>

				<v-row><v-col cols="12"></v-col></v-row>
                
                <v-row>
                    <v-col cols="12">
                        <v-tabs>
                            <v-tab v-on:click="filterType('open')">Trades Open</v-tab>
                            <v-tab v-on:click="filterType('close')">Trades Close</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
                
				<v-row><v-col cols="12"></v-col></v-row>
                
                <v-row>
                    <v-col lg="11" md="11" xs="12" cols="12">
                        <v-text-field 
                            class="mr-4 mt-n2"
                            v-model="search"
                            append-icon="mdi-search"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                    <v-col lg="1" md="1" xs="12" cols="12">
                        <download-excel :data="tableData" :name="selectedBrokerId + '_' + selectedSymbol + '_detail_' + selectedBook">
                            <v-btn color="#08591c" class="accent-4">To CSV</v-btn>
                        </download-excel>
                    </v-col>
                    <v-col cols="12">
                        <v-card flat>
                        
                            <v-data-table :search="search" :headers="headers" :items="tableData" 
                                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :custom-filter="filterTable" 
                                class="elevation-1" :items-per-page="itemsPerPage"
                                :loading="tableLoading"
                                loading-text="Loading data... Please allow some time to wait large datasets">
                                
                                <template v-slot:item.login="{ item }">
                                    <span>{{ getLogin(item) }}</span>
                                </template>
                                <template v-slot:item.total_open_buy="{ item }">
                                    {{ item.total_open_buy/100}}
                                </template> 
                                <template v-slot:item.total_close_buy="{ item }">
                                    {{ item.total_close_buy/100}}
                                </template> 
                                <template v-slot:item.total_open_sell="{ item }">
                                    {{ item.total_open_sell/100}}
                                </template> 
                                <template v-slot:item.total_close_sell="{ item }">
                                    {{ item.total_close_sell/100}}
                                </template> 

                                <template v-slot:item.net_open="{ item }">
                                    {{ (item.total_open_buy/100 - item.total_open_sell/100).toFixed(2) }}
                                </template> 

                                <template v-slot:item.average_open_buy_price="{ item }">
                                    {{ item.average_open_buy_price.toFixed(5) }}
                                </template> 

                                <template v-slot:item.average_open_sell_price="{ item }">
                                    {{ item.average_open_sell_price.toFixed(5) }}
                                </template> 

                            </v-data-table>

                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiSummarySymbol from "@components/api/pk/symbol-summary";
import Breadcrumbs from "@components/Breadcrumbs";
import { position } from "@components/mixins/position";
export default {
    components: {Breadcrumbs},
    mixins: [position],
    data() {
        return {
            ws: null,
            selectedSymbol: "",
            selectedBrokerId: -1,
            selectedBrokerName: "",
            selectedBook: "",
            tableData: [],
            sortBy: "total_open_buy",
            sortDesc: true,
            search: "",
            itemsPerPage: -1,
            tableLoading: false,
            headers:[
                { text: "Login", value: "login", align: 'left' },
                { text: "Buy", value: "total_open_buy", align: 'left' },
                { text: "Buy", value: "total_close_buy", align: ' d-none' },
                { text: "Sell", value: "total_open_sell", align: 'left' },
                { text: "Net", value: "net_open", align: 'left' },
                { text: "Sell", value: "total_close_sell", align: ' d-none' },
                { text: "Buy Price", value: "average_open_buy_price", align: 'left' },
                { text: "Sell Price", value: "average_open_sell_price", align: 'left' },
            ]
        }
    },
    watch: {

    },
    methods: {
        filterType(type) { 

            if (type == "open") {
                for (let i = 0; i < this.headers.length; i++) {
                    if (this.headers[i].value == "login") continue;
                    
                    if (this.headers[i].value == "total_open_buy" || this.headers[i].value == "total_open_sell" ||
                        this.headers[i].value == "average_open_buy_price" || this.headers[i].value == "average_open_sell_price" ||
                        this.headers[i].value == "net_open") {
                        this.headers[i].align = "left";
                    }

                    if (this.headers[i].value == "total_close_buy" || this.headers[i].value == "total_close_sell") {
                        this.headers[i].align = " d-none";
                    }
                }
            }

            if (type == "close") {
                for (let i = 0; i < this.headers.length; i++) {
                    if (this.headers[i].value == "login") continue;
                    
                    if (this.headers[i].value == "total_open_buy" || this.headers[i].value == "total_open_sell" ||
                        this.headers[i].value == "average_open_buy_price" || this.headers[i].value == "average_open_sell_price" ||
                        this.headers[i].value == "net_open") {
                        this.headers[i].align = " d-none";
                    }

                    if (this.headers[i].value == "total_close_buy" || this.headers[i].value == "total_close_sell") {
                        this.headers[i].align = "left";
                    }
                }
            }
        },
		filterTable (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().indexOf(search) !== -1
		},
        loadSymbolSummary() {
            let self = this;
            
            let finalFilterSymbol = [];

            if (self.selectedSymbol != "" && self.selectedSymbol != undefined && self.selectedSymbol != null) {
                finalFilterSymbol.push(self.selectedSymbol);
                for (let i = 0; i < this.$store.state.symbolEndfix.length; i++) {
                    let endFixSymbol = "";
                    if (this.$store.state.symbolEndfix[i].indexOf("-") != -1) {
                        endFixSymbol = self.selectedSymbol + this.$store.state.symbolEndfix[i].toUpperCase();
                    } else {
                        endFixSymbol = self.selectedSymbol + this.$store.state.symbolEndfix[i].toLowerCase();
                    }
                    finalFilterSymbol.push(endFixSymbol);
                }
            }

            let params = {
                broker_id: self.selectedBrokerId, 
                symbol: finalFilterSymbol, 
                book: self.selectedBook
            };
            self.tableLoading = true;
            apiSummarySymbol.create(params).then(res => {
                let data = res.data;
                console.log(res);
                localStorage.setItem("bookSymbolSummary", JSON.stringify(data));
                self.tableData = data;
                self.tableLoading = false;
            })
        },
        getLogin(data) {
            if (data.otb_login != null) return data.otb_login;
            if (data.ctb_login != null) return data.ctb_login;
            if (data.ots_login != null) return data.ots_login;
            if (data.cts_login != null) return data.cts_login;
        },
    },
    mounted() {
        console.log("Search: ", this.search);
        // path: 'summary-symbol/:broker/:name/:symbol/:book',
        this.selectedSymbol = this.$route.params.symbol; 
        this.selectedBrokerId = this.$route.params.broker; 
        this.selectedBook = this.$route.params.book; 
        this.loadSymbolSummary();
    }
}
</script>