import request from '@utils/pk/request'

export function fetch(query) {
    return request({
        url: 'symbol/summary',
        method: 'get',
        params: query
    })
}

export function create(query) {
    return request({
        url: 'symbol/summary',
        method: 'post',
        data: query
    })
}

export function update(query) {
    return request({
        url: 'symbol/summary',
        method: 'put',
        data: query
    })
}

export function remove(query) {
    return request({
        url: 'symbol/summary',
        method: 'delete',
        data: query
    })
}