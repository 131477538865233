var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-container',{attrs:{"fluid":"","pt-0":"","mt-0":""}},[_c('v-row',{staticClass:"blue-grey darken-4"},[_c('Breadcrumbs')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',[_c('v-tab',{on:{"click":function($event){return _vm.filterType('open')}}},[_vm._v("Trades Open")]),_c('v-tab',{on:{"click":function($event){return _vm.filterType('close')}}},[_vm._v("Trades Close")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"lg":"11","md":"11","xs":"12","cols":"12"}},[_c('v-text-field',{staticClass:"mr-4 mt-n2",attrs:{"append-icon":"mdi-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"lg":"1","md":"1","xs":"12","cols":"12"}},[_c('download-excel',{attrs:{"data":_vm.tableData,"name":_vm.selectedBrokerId + '_' + _vm.selectedSymbol + '_detail_' + _vm.selectedBook}},[_c('v-btn',{staticClass:"accent-4",attrs:{"color":"#08591c"}},[_vm._v("To CSV")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.tableData,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-filter":_vm.filterTable,"items-per-page":_vm.itemsPerPage,"loading":_vm.tableLoading,"loading-text":"Loading data... Please allow some time to wait large datasets"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.login",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getLogin(item)))])]}},{key:"item.total_open_buy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_open_buy/100)+" ")]}},{key:"item.total_close_buy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_close_buy/100)+" ")]}},{key:"item.total_open_sell",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_open_sell/100)+" ")]}},{key:"item.total_close_sell",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_close_sell/100)+" ")]}},{key:"item.net_open",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.total_open_buy/100 - item.total_open_sell/100).toFixed(2))+" ")]}},{key:"item.average_open_buy_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.average_open_buy_price.toFixed(5))+" ")]}},{key:"item.average_open_sell_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.average_open_sell_price.toFixed(5))+" ")]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }